import { usePinPointsGroupsLeaflet } from '@entegral/domains.pin-point/hooks';
import classnames from 'classnames';
import { getStatusType } from '../utils/get-status-type';

export function usePropertiesGroups(properties, zoom, context) {
  return usePinPointsGroupsLeaflet(properties, zoom, hydrateGroupStrategy, context);
}

function hydrateGroupStrategy(group, zoom, context) {
  group.count = group.items.length;
  group.summary = `${group.count}&nbsp;units`;
  group.details = getDetails(group, context);
  group.caption = getCaption(group);
  group.className = getClassName(group, context);
}

function getDetails(group, context) {
  let details = ``;
  let className = `${context.baseClassName}-detail`;

  for (let property of group.items) {
    details += `
<a href="${property.url}" target="_blank" class="${className} ${className}_status_${getStatusType(property.status)}">
  <div class="${className}__heading">${property.heading}</div>
  <img class="${className}__image" src="${property.thumbnail}">
</a>
`;
  }

  let detailsClassname = classnames(
    `${context.baseClassName}-details`,
    {
      [`${context.baseClassName}-details_type_single`]: group.items.length === 1,
    }
  );

  return `<div class="${detailsClassname}">${details}</div>`;
}

function getCaption(group) {
  let isSingleArea = group.items.length === 1;

  return isSingleArea ? group.items[0].shortPrice.replace(/ /ig, '&nbsp;') : `${group.items.length}&nbsp;units`;
}

function getClassName(group, context) {
  let statusType = getStatusTypeForGroup(group.items);

  return classnames(
    `${context.baseClassName}__marker_status_${statusType}`,
    {
      [`${context.baseClassName}__marker_location_exact`]: isExactCoords(group.items),
    }
  );
}

function isExactCoords(properties) {
  return properties.every(x => x.isExactCoords);
}

function getStatusTypeForGroup(properties) {
  let { status } = properties[0];

  let monotone = properties.every(x => x.status === status);

  if (!monotone) return `mixed`;

  return getStatusType(status);
}
