import React, { useMemo } from 'react';
import { usePropertiesGroups } from '../hooks/properties-groups';
import { ReviewMap } from '@entegral/components.review-map';
import { useEnumFilter } from '../hooks/enum-filter';
import { useRangeFilter} from '../hooks/range-filter';
import { StatusFilter } from './status-filter';
import { TypeFilter } from './type-filter';
import { PriceFilter } from './price-filter';
import { Legend } from './legend';

export function PropertiesReviewMap({
  properties,
  currency,
  tilesId,
  accessToken,
  baseClassName=`properties-review-map`,
  useCtrlScrollZoom,
  defaultViewCoords,
  mapAlias,
}) {
  let context = useMemo(
    () => ({ baseClassName }),
    [baseClassName]
  );

  let [statusFilter, toggleStatusFilter] = useEnumFilter(properties, 'status');
  let [typeFilter, toggleTypeFilter] = useEnumFilter(properties, 'type');

  let filteredProperties;

  filteredProperties = useMemo(
    () => properties.filter(x => statusFilter[x.status] && typeFilter[x.type]),
    [properties, statusFilter, typeFilter],
  );

  let [priceFilter, setMinPrice, setMaxPrice] = useRangeFilter(filteredProperties, 'price');

  filteredProperties = useMemo(
    () => filteredProperties.filter(x => x.price >= priceFilter.min && x.price <= priceFilter.max),
    [filteredProperties, priceFilter],
  );

  return (
    <div className={baseClassName}>

      <StatusFilter
        baseClassName={baseClassName}
        filter={statusFilter}
        toggle={toggleStatusFilter}
      />

      <TypeFilter
        baseClassName={baseClassName}
        filter={typeFilter}
        toggle={toggleTypeFilter}
      />

      <PriceFilter
        baseClassName={baseClassName}
        filter={priceFilter}
        currency={currency}
        setMin={setMinPrice}
        setMax={setMaxPrice}
      />

      <ReviewMap
        pinPoints={filteredProperties}
        pinPointsGroupsHook={usePropertiesGroups}
        tilesId={tilesId}
        accessToken={accessToken}
        baseClassName={baseClassName}
        context={context}
        useCtrlScrollZoom={useCtrlScrollZoom}
        defaultViewCoords={defaultViewCoords}
        mapAlias={mapAlias}
      />

      <Legend baseClassName={baseClassName} />

    </div>
  );
}
